// external dependencies
import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

// gatsby dependencies
import { graphql, useStaticQuery } from 'gatsby';

// local dependencies
import SEO from '../components/SEO';
import Cta from '../components/shared/Cta';
import Hero from '../components/shared/Hero';
import Section from '../components/shared/Section';
import ServiceCard from '../components/shared/ServiceCard';
import SuperText from '../components/shared/SuperText';
import EnhancedGrid from '../components/shared/EnhancedGrid';

const StyledTextWrapper = styled.div`
  display: block;
  text-align: center;
  max-width: 650px;
  margin: auto;

  h3 {
    color: ${(props) => props.theme.colors.blue};
    margin-bottom: 2rem;
  }

  p {
    color: ${(props) => props.theme.colors.darkGray};
    margin: 1.5rem auto 2rem auto;
  }
`;

// component definition
const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      services: allContentfulServicePageTemplate {
        nodes {
          hero {
            headline
            image {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              title
            }
          }
          pageTitle
          slug
        }
      }
      page: contentfulServicePage {
        pageTitle
        metaDescription
        hero {
          buttonLink
          buttonText
          headline
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            title
          }
          supertext
        }
        textBlock {
          textBlockButtonLink
          textBlockSupertext
          textBlockHeadline
          textBlockButtonText
          textBlockBody {
            textBlockBody
          }
        }
        ctaSection {
          email
          headline
          phoneNumber
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            title
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO
        description={data.page.metaDescription}
        title={data.page.pageTitle}
      />
      <Hero
        short
        bkgImage={data.page.hero.image}
        headline={data.page.hero.headline}
        buttonLink={data.page.hero.buttonLink}
        buttonText={data.page.hero.buttonText}
        supertext={data.page.hero.supertext}
      />
      <Section>
        <StyledTextWrapper>
          <SuperText center title={data.page.textBlock.textBlockSupertext} />
          <h3>{data.page.textBlock.textBlockHeadline}</h3>
          <p>{data.page.textBlock.textBlockBody.textBlockBody}</p>
        </StyledTextWrapper>
      </Section>
      <Section>
        <EnhancedGrid
          container
          justifyContent='center'
          alignItems='flex-start'
          spacing={2}
        >
          {data.services.nodes.map((service, i) => (
            <Grid item key={i} xs={12} sm={12} md={4}>
              <ServiceCard
                slug={service.slug}
                name={service.hero.headline}
                image={service.hero.image}
              />
            </Grid>
          ))}
        </EnhancedGrid>
      </Section>
      <Section></Section>
      <Cta
        ctaSupertext={data.page.ctaSection.supertext}
        ctaHeadline={data.page.ctaSection.headline}
        phoneNumber={data.page.ctaSection.phoneNumber}
        email={data.page.ctaSection.email}
        ctaImage={data.page.ctaSection.image}
      />
    </>
  );
};

export default ServicesPage;
