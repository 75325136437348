// external dependencies
import React from 'react';
import styled from 'styled-components';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// gatsby dependencies
// import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, Link, useStaticQuery } from 'gatsby';

const StyledBackgroundImage = styled(GatsbyImage)`
  width: 100%;
  /* height: ${(props) => (props.small ? '230px' : '300px')}; */
  position: relative;
  box-shadow: ${(props) => props.theme.effects.boxShadow};
  border-radius: ${(props) => props.theme.elements.borderRadius};
  margin: 10px auto;
  position: relative;
`;

const StyledNameWrapper = styled.div`
  background-color: var(--scp-light-gray);
  min-height: 75px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--scp-blue);
  padding: 15px;
  text-align: left;

  h5 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 0.8;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
  }
`;

// component definition
const ServiceCard = ({ slug, image, name, small }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholder: allFile(
        filter: { sourceInstanceName: { eq: "placeholders" } }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const placeholderImage = data.placeholder.nodes[0].childImageSharp.gatsbyImageData;
  return (
    <Link to={slug ? slug : '/contact'}>
      <div style={{ display: 'grid', position: 'relative', height: small ? '250px' : '350px', width: '100%' }} >
        <GatsbyImage
          image={image ? image.gatsbyImageData : placeholderImage}
          small={small}
          style={{
            gridArea: "1/1",
          }}
        />
        <StyledNameWrapper>
          <h5>{name ? name : 'No service name'}</h5>
          <ArrowForwardIcon htmlColor='#ebaa20' />
        </StyledNameWrapper>
      </div>
    </Link>
  );
};

export default ServiceCard;
